import React, {Component} from "react";
import IConnectionIndicatorProperties from './IConnectionIndicatorsProperties';


class ConnectionIndicators extends Component<IConnectionIndicatorProperties, any> {
    public render = () => {
        return (this.props.backendConnected ?
            (<div style={{width: "100%"}}>
                <div style={{
                    float: 'left', alignContent: "center", display: 'none',
                    backgroundColor: this.props.audioRecognized ? "green" : "black",
                    paddingLeft: 5, paddingRight: 5
                }}>
                    {this.props.audioRecognized ? "audio match" : "no audio match"}
                </div>
                <div style={{
                    float: 'right', alignContent: "center",
                    backgroundColor: this.props.faceRecognized && this.props.controlEnabled ? "green" : "black",
                    paddingLeft: 5, paddingRight: 5
                }}>
                    {this.props.dialogMessage || (this.props.controlEnabled ? (this.props.faceRecognized ? "Nice to see you, Maestro!" : "Maestro, we can't see you") : "...")}
                </div>
            </div>) :
            (<div style={{
                float: 'left', alignContent: 'center',
                backgroundColor: 'black'
            }}>
                No Connection
            </div>))
    }
}

export default ConnectionIndicators;