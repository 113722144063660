/* eslint-disable */
import * as React from "react";
import {IYoutubeContainerOwnProps} from "./IYoutubeContainerOwnProps";
import styles from './YoutubeContainer.module.css';
import {ScriptCache} from "../../../ScriptCache";
import {IYoutubeContainerState} from "./IYoutubeContainerState";
import {PlaybackState} from "../../../types/PlaybackState";
import * as Constants from "../../../util/Constants";

class YoutubeContainer extends React.Component <IYoutubeContainerOwnProps, IYoutubeContainerState> {

    private loadedVideoApi: boolean = false;
    private player: any;
    private interval: any;
    private current_time: number = 0;
    private last_time_update: number = 0;

    public constructor(props: IYoutubeContainerOwnProps) {
        super(props);

        this.state = {
            loadingTimeout: {},
            hasError: false
        };
    }

    private update_time(): void {

        if (!this.player) {
            return;
        }

        let time_update = this.player.getCurrentTime();

        let playing = this.player.getPlayerState();

        if (playing == 1) {

            if (this.last_time_update == time_update) {
                this.current_time += 25;
            }

            if (this.last_time_update != time_update) {
                this.current_time = time_update;
            }
        }

        this.last_time_update = time_update;
    }

    public getCurrentTime(): number {
        return this.current_time;
    }

    public playVideo(videoId: string, offset?: number) {
        this.player && this.player.loadVideoById(videoId, offset);
        clearInterval(this.interval);
        this.update_time();
        this.interval = setInterval(this.update_time.bind(this), 25);
    }

    public pauseVideo() {
        this.player && this.player.pauseVideo();
        clearInterval(this.interval);
    }

    public componentDidMount(): void {
        new ScriptCache([
            {
                name: 'https://www.youtube.com/player_api',
                callback: () => {
                    setTimeout(() => {
                        // @ts-ignore
                        new window.YT.Player("video", {
                            width: Constants.SELECTED_THUMBNAIL_WIDTH,
                            height: Constants.SELECTED_THUMBNAIL_HEIGHT,
                            events: {
                                'onReady': (event: any) => {
                                    this.player = event.target
                                },
                                'onError': this.onPlayerError.bind(this),
                                'onStateChange': this.onPlayerStateChange.bind(this)
                            },
                            playerVars: {
                                autoplay: 0,
                                rel: 0,
                                disablekb: 1,
                                playsinline: 1
                            }
                        });
                        this.loadedVideoApi = true;
                    }, 500)
                }
            },
        ]);
    }

    private onPlayerError(event: any): void {
        console.log("player error", event.data);
    }

    private onPlayerStateChange(event: any) {

        const playerState = event.data;
        const player = event.target;

        if (typeof player === 'undefined') {
            // console.log("got stateChange on undefined player?");
            return;
        }

        if (typeof playerState === "undefined") {
            // console.log("got undefined player state?");
            return;
        }

        if (typeof player.seekTo !== 'function'
            || typeof player.getVideoData() === undefined) {
            // console.log("Player not fully loaded");
            return;
        }
        const videoId = event.target.getVideoData().video_id;

        console.log("on player state change for " + videoId + " player state " + playerState);

        switch (playerState) {
            case 1: // PLAYING state
                this.update_time();
                this.props.onYoutubePlayerStateChange(PlaybackState.PLAYING);
                return;

            case 2: // PAUSED state
                this.props.onYoutubePlayerStateChange(PlaybackState.PAUSED);
                return;

            case 0: // ENDED state
                this.props.onYoutubePlayerStateChange(PlaybackState.PAUSED);
                return;

            case 3: // BUFFERING state
                this.props.onYoutubePlayerStateChange(PlaybackState.BUFFERING);
                return;

            case 5: // CUED state
                this.props.onYoutubePlayerStateChange(PlaybackState.PAUSED);
                return;

            default:
                return;
        }
    }

    static getDerivedStateFromError(error: any) {
        // Update state so the next render will show the fallback UI.
        return {hasError: true};
    }

    componentDidCatch(error: any, info: React.ErrorInfo) {
        // You can also log the error to an error reporting service
        console.error(error, info);
    }


    public componentWillUnmount(): void {
        this.player.destroy();
    }

    public render = () => {

        if (this.state.hasError) {
            return <div>Error</div>
        }

        return (
            <div className={styles.videoMainContainer}
                 style={{display: "inline-block"}}>
                <div className={styles.videoDivContainer}>

                    <div
                        id={"videoContainer"}
                        className={styles.playingVideoDiv}>
                        <div id={"video"}/>
                    </div>

                    {
                        <div style={{
                            width: 180, marginBottom: 2
                        }}>
                            <p className={styles.metaText}>

                            </p>
                        </div>
                    }

                </div>

            </div>
        )
    }
}

export default YoutubeContainer;