import React, {Component} from 'react';
import './IntroScreen.css';
import StartButton from "../StartButton";

import {FormattedMessage} from "react-intl";


class IntroScreenTrain extends Component<{onDone: () => void},  {}> {

    onClickStart() {
        this.props.onDone();
    }

    // TODO: implement flow based on https://developer.mozilla.org/en-US/docs/Web/API/MediaDevices/getUserMedia;
    // use its callbacks for updating the component state and ultimately calling this.props.isDone();
    // when available, rely on navigator.permissions for fast permissions checking.
    render() {

        const controls = <StartButton onClickStart={this.onClickStart.bind(this)}/>;

        return (
            <div className='Intro-main'>
                <div className='Intro-title'>
                    <div className='Title-text'>
                        Music AI Trainer
                    </div>
                </div>
                <div className='Intro-slogan'>
                    <FormattedMessage
                        id="app.slogan"
                        defaultMessage='Show What You Hear In Music'
                    />
                </div>
                <div className='Intro-controls'>
                    {controls}
                </div>
                <div className='Intro-terms'>
                    <FormattedMessage
                        id="app.terms0"
                        defaultMessage="By using this application you agree to your audio and video data recorded through the application to be used for research purposes by {link}. The recordings will never be published without your consent."
                        values={{
                            link: <a href="https://www.peachnote.com/impressum" target="_blank" rel="noopener noreferer">
                                Peachnote
                            </a>
                        }}
                    />
                </div>
            </div>
        );
    }
}

export default IntroScreenTrain