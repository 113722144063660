import React, {Component} from 'react';
import './StartButton.css';

import {FaVideo} from "react-icons/fa";
import {FormattedMessage} from "react-intl";

class StartButton extends Component<any, any> {

    render() {
        return (
            <div className='Start-main' onClick={this.props.onClickStart}>
                <FaVideo size="calc(30px + 3.5vmin)" color={"black"}/>
                <div className='Start-text'>
                    <FormattedMessage
                        id="app.startButton"
                        defaultMessage='Start'
                    />
                </div>
            </div>
        )
    }

}

export default StartButton