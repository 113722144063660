import React, {Component, useState} from 'react';
import './ControlButton.css';

import {FaMicrophone, FaEye} from "react-icons/fa";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import {FormattedMessage} from "react-intl";

interface Props {
    onPress: (touched: boolean) => void;
}
const ControlButton: React.FC<Props> = ({onPress}) => {
    const [touched, setTouched] = useState(false);

    const handlePress = () => {
        setTouched(true);
        onPress(true);
    };

    const handleRelease = () => {
        setTouched(false);
        onPress(false);
    };


    return (
        <div className='Start-main'
             style={{backgroundColor: touched ? "navajowhite" : "ghostwhite"}}
             onTouchStart={handlePress}
             onMouseDown={handlePress}

             onMouseUp={handleRelease}
             onMouseLeave={handleRelease}
             onTouchEnd={handleRelease}
             onTouchCancel={handleRelease}
        >
            <div style={{display: "flex", flex: "0 1 content", justifyContent: "space-between", alignItems: "center", width: "calc(75px + 3vmin)"}}>
                <FaEye size="calc(25px + 2.7vmin)" color={"black"}/>
                <FaMicrophone size="calc(25px + 2.7vmin)" color={"black"}/>
            </div>
            <div className='Start-text'>
                <FormattedMessage
                    id="app.controlButton"
                    defaultMessage='Show/Tell'
                />
            </div>
        </div>
    )
}

export default ControlButton