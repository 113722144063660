import React, {Component} from 'react';
import './PermissionRequestDialog.css';

import {FormattedMessage} from "react-intl";

class PermissionRequestDialog extends Component<{ onClickOk: () => void, onClickCancel: () => void, mode: string }, {}> {
    render() {
        const permissionText = this.props.mode === 'audio'
            ? <div className='Permission-text'>
                <FormattedMessage
                    id="app.audioPermissionRequest0"
                    defaultMessage='We need to access your microphone in order to recognize the music you are listening to.'
                    // defaultMessage='We need to access your microphone in order match you with others listening to the same radio or TV station.'
                />
              </div>
            : <div className='Permission-text'>
                <FormattedMessage
                    id="app.videoPermissionRequest0"
                    defaultMessage='We need to access your camera in order to capture your facial expressions.'
                />
            </div>;
        return (
            <div className='Permission-main'>
                {permissionText}
                <div className='Permission-buttons'>
                    <div className='Cancel-button' onClick={this.props.onClickCancel}>
                        <FormattedMessage
                            id="app.cancel"
                            defaultMessage='cancel'/>
                    </div>
                    <div className='Ok-button' onClick={this.props.onClickOk}>
                        <FormattedMessage
                            id="app.ok"
                            defaultMessage='OK'/>
                    </div>
                    {window.navigator.userAgent.indexOf("LaLaMime") < 0 &&
                    <div className='placeholder'/>
                    }
                </div>
            </div>
        )
    }

}

export default PermissionRequestDialog