import React, {Component} from 'react';
import '../IntroScreenTrain/IntroScreen.css';

import PermissionRequestDialog from "../PermissionRequestDialog/PermissionRequestDialog";

type PermissionScreenProps = {
    onDone: () => void, onCancel: () => void, isWebView: boolean
}

type PermissionScreenState = {
    microphoneGranted: boolean, cameraGranted: boolean
}


class PermissionScreen extends Component<PermissionScreenProps, PermissionScreenState> {

    constructor(props: any) {
        super(props);
        this.state = {
            microphoneGranted: false,
            cameraGranted: false
        }
    }

    public requestPermission(mode: string) {
        const constraints: { [mode: string]: any } = {};
        constraints[mode] = mode === 'audio'
            ? {
                ideal: {
                    echoCancellation: true,
                    googEchoCancellation: false,
                    googAutoGainControl: false,
                    googNoiseSuppression: false,
                    googHighpassFilter: false,
                    googTypingNoiseDetection: false,
                    googAudioMirroring: false,
                    googNoiseReduction: false,
                },
                optional: []
            }
            : {facingMode: {ideal: "user"}};


        navigator.mediaDevices.getUserMedia(constraints)
            .then((stream) => {
                if (stream.active) {
                    console.log(`${mode} permission granted`);
                    if (mode === 'audio') {
                        this.setState({
                            microphoneGranted: true
                        });
                        if (this.state.cameraGranted) {
                            this.props.onDone();
                        }
                    } else if (mode === 'video') {
                        this.setState({
                            cameraGranted: true
                        });
                        if (this.state.microphoneGranted) {
                            this.props.onDone();
                        }
                    }
                }
            })
            .catch(function (err) {
                console.log(`Error requesting ${mode} permission:`, err)
            });
    }

    public componentDidMount(): void {

        if (this.props.isWebView) {
            console.log("checking permissions");
            // @ts-ignore
            const microphoneGranted = window.interface.isAudioRecordingPermissionGranted();
            console.log("microphone granted: " + microphoneGranted);
            // @ts-ignore
            const cameraGranted = window.interface.isCameraPermissionGranted();
            console.log("camera granted: " + cameraGranted);

            this.setState({
                microphoneGranted: microphoneGranted,
                cameraGranted: cameraGranted
            });

            if (microphoneGranted && cameraGranted) {
                this.props.onDone();
                return;
            }

            // @ts-ignore
            window.audioPermissionGranted = (granted: any) => {
                console.log("audio granted " + granted);
                if (granted) {
                    this.setState({microphoneGranted: true});
                    if (this.state.cameraGranted) {
                        this.props.onDone();
                    }
                } else {
                    this.setState({microphoneGranted: false});
                }
            };

            // @ts-ignore
            window.cameraPermissionGranted = (granted: any) => {
                console.log("camera granted " + granted);
                if (granted) {
                    this.setState({cameraGranted: true});
                    if (this.state.microphoneGranted) {
                        this.props.onDone();
                    }
                } else {
                    this.setState({cameraGranted: false});
                }
            }
        }

        // @ts-ignore
        if (typeof navigator.permissions === 'undefined' || typeof navigator.permissions.query === 'undefined') {
            return;
        }

        // @ts-ignore
        navigator.permissions.query({name: 'microphone'}).then(result => {
            if (result.state === 'granted') {
                this.setState({microphoneGranted: true});
                if (this.state.cameraGranted) {
                    this.props.onDone();
                }
            } else {
                this.setState({microphoneGranted: false});
            }
        });

        // @ts-ignore
        navigator.permissions.query({name: 'camera'}).then(result => {
            if (result.state === 'granted') {
                this.setState({cameraGranted: true});
                if (this.state.microphoneGranted) {
                    this.props.onDone();
                }
            } else {
                this.setState({cameraGranted: false});
            }
        });

    }

    // TODO: implement flow based on https://developer.mozilla.org/en-US/docs/Web/API/MediaDevices/getUserMedia;
    // use its callbacks for updating the component state and ultimately calling this.props.isDone();
    // when available, rely on navigator.permissions for fast permissions checking.
    render() {
        const permissionMode = !this.state.microphoneGranted
            ? 'audio'
            : !this.state.cameraGranted
                ? 'video'
                : '';

        const controls =
            <PermissionRequestDialog
                mode={permissionMode}
                onClickOk={this.requestPermission.bind(this, permissionMode)}
                onClickCancel={this.props.onCancel}
            />;

        return (
            <div className='Intro-main'>
                <div className='Intro-controls'>
                    {controls}
                </div>
            </div>
        );
    }
}

export default PermissionScreen