let TrainingRecordings: string[][];
TrainingRecordings = [
    [
        "tmXhWy0jT1Q",
        "OtsKnpQIe_s",
        //"Yh36PaE-Pf0"
    ],
    [
        "147-ttSq4tg",
        "1qrDfMIAzlA",
        //"dhN1zXLPvAI",
    ],
    [
        "J27-cACv0zQ",
        "VDhDv6zl8Ow",
        //"ItmqThCtDjs"
    ],
    [
        "-6fzbB92C3o",
        "ONUIZ3rTgYU",
        //"Ba4PErQMmFI",
    ],
    ["9oIdtq9E2ZU","GsEYaKsb60","pQgEgvMmCVo","2jivu8n5KRM","UYtcuTiguxw"],
    ["vApbLxmg50M","PweZqcYhH50","ZasGYOngWAo","4nutp7R3EWQ","WtIPHQwq2mg"],
    ["sLDvPeFLMeE","E9khUgdVbQ4","wiqzxg1wi68","QaBrjn3Nn6M","D-O2wdFU1RA"],
    ["nPOO1CNzt7g","ejs9ZlCwY9Y","xpgtxEZ_E7A","qoZqhLifxg4","qFmLW8gPeVw"],
    ["XXnpR_bhY0E","19mokju9ivM","BftJ2MVQLAk","VKHWk6_7ANA","7xY1VnT1Bdw"],
    ["g475bZS82Pw","-gN2uOWZwRY","Gk11SsmQLyA","4lbl1yb_pz8","HxMQCgE4zh0"],
    ["luo4wUQMwYY","ButtZVb4dm0","RYKn_1tX53U","0KIFIcDxetw","2KhVJ7LJZ8A"],
    ["UHw5lXM6l3E","mbYeVXQpAFo","ESoiXGOofqs","LLqw0ZxWYUU","R8gEOufQbbI"],
    ["RvjP2-Oxmuc","Yd7NlmxOWSM","h3MPnn1Wys","pbndMA0IzUQ","HYOf87NjbZ8"],
    ["ad-lzjKarKA","NeahBzkQEk","wi_kxIIjRGA","qPC3UQRwD7A","8obdWshfySI"],
    ["gppem_XGdcs","U5hEWAukqmc","QuAL37Mxs4","q5OaSju0qNc","mwb_OGibysg"],
    ["8PuDl81uvE","QHT5IIaZo3k","xCgSqxoXvYA","_TRqmRx7u38","MDkFFYKgaU0"],
    ["cGDY1ZgIzZ8","8zlse7GEN3k","M6PaevjHKDk","RecjZsxEWo0","uQw3MyUBHLw"],
    ["AtHygtb-HHU","lotlFkSLnp0","AteBxC_unw0","iGq70HCq7bQ","4lR_wEt0EAU"],
    ["g55Jk4yyTDk","9Ll_GV6BkPU","yg9gfDxjBBg","nG84-ImJaI8","Rfk_hJG0hP4"],
    ["2lDTS7iFiqc","dL0JLNt_3EE","e2OgQ27BQNk","QrdQTuDTwgI","Wzr7FdzITdM"],
    ["aecvdNPJeqM","JEZfhpWVzE","KROkCoem1o0","LdG9cv1wGuo","uwQ_SXQfTMY"],
    ["a_VQNDB-PeY","wIbN4z4AeGI","x4EaArI05bo","7G9hav6qFSA","yJkXvPpY7tg"],
    ["Y6hVP7vUAWs","n4XRn3k1D6U","tIA5gpxr7f4","nlUEgtL9gdw","_wHH-p3Zvmc"],
    ["Ida_QzJMZKI","TkoQSh9h-k0","upfpl1SHn8U","spaaGHOQlJE","tUb3EszM23c"],
    ["lZBkxRy2hoo","NAtlFna4RYE","xeuMbqnTmUA","YMZQ_Lh635w","b07pRWDZDiM"],
    ["y-wK6BWWd1k","EL5wIn88gLI","ITu-u1HwC3Y","BliNZirtS2Q","-E8nE2Xnj7s"],
    ["Te7wLu2Zqb8","e5kTffxNROM","qFKT6eyPNVs","t3NBxMQG43E","Y3i9kneYCWc"],
    ["VQE1FP7m55M","RPuxsjg541o","exKxO6qcDik","I9l1YubDvpQ","woeeDllbWys"],
];

export default TrainingRecordings;