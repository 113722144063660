import React, {Component, createRef} from 'react';
import {isIOS, osVersion, isMobileSafari, isSafari} from "react-device-detect";

import {ScriptCache} from "../../ScriptCache";

import IWebrtcConnectorProperties from "./IWebrtcConnectorProperties";
import {IGestureMatch} from "../../IGestureMatch";
import {FormattedMessage, injectIntl} from 'react-intl';

import './WebrtcConnector.css';
import {getBackendDomain} from "../../util/Api";

const BandwidthHandler = (function () {
    function setBAS(sdp: any, bandwidth: any, isScreen: any) {
        // @ts-ignore
        if (!!navigator.mozGetUserMedia || !bandwidth) {
            return sdp;
        }

        if (isScreen) {
            if (!bandwidth.screen) {
                console.warn('It seems that you are not using bandwidth for screen. Screen sharing is expected to fail.');
            } else if (bandwidth.screen < 300) {
                console.warn('It seems that you are using wrong bandwidth value for screen. Screen sharing is expected to fail.');
            }
        }

        // if screen; must use at least 300kbs
        if (bandwidth.screen && isScreen) {
            sdp = sdp.replace(/b=AS([^\r\n]+\r\n)/g, '');
            sdp = sdp.replace(/a=mid:video\r\n/g, 'a=mid:video\r\nb=AS:' + bandwidth.screen + '\r\n');
        }

        // remove existing bandwidth lines
        if (bandwidth.audio || bandwidth.video || bandwidth.data) {
            sdp = sdp.replace(/b=AS([^\r\n]+\r\n)/g, '');
        }

        if (bandwidth.audio) {
            sdp = sdp.replace(/a=mid:audio\r\n/g, 'a=mid:audio\r\nb=AS:' + bandwidth.audio + '\r\n');
        }

        if (bandwidth.video) {
            sdp = sdp.replace(/a=mid:video\r\n/g, 'a=mid:video\r\nb=AS:' + (isScreen ? bandwidth.screen : bandwidth.video) + '\r\n');
        }

        return sdp;
    }

    // Find the line in sdpLines that starts with |prefix|, and, if specified,
    // contains |substr| (case-insensitive search).
    function findLine(sdpLines: any, prefix: any, substr: any) {
        return findLineInRange(sdpLines, 0, -1, prefix, substr);
    }

    // Find the line in sdpLines[startLine...endLine - 1] that starts with |prefix|
    // and, if specified, contains |substr| (case-insensitive search).
    function findLineInRange(sdpLines: any, startLine: any, endLine: any, prefix: any, substr: any) {
        const realEndLine = endLine !== -1 ? endLine : sdpLines.length;
        for (var i = startLine; i < realEndLine; ++i) {
            if (sdpLines[i].indexOf(prefix) === 0) {
                if (!substr ||
                    sdpLines[i].toLowerCase().indexOf(substr.toLowerCase()) !== -1) {
                    return i;
                }
            }
        }
        return null;
    }

    // Gets the codec payload type from an a=rtpmap:X line.
    function getCodecPayloadType(sdpLine: any) {
        const pattern = new RegExp('a=rtpmap:(\\d+) \\w+\\/\\d+');
        const result = sdpLine.match(pattern);
        return (result && result.length === 2) ? result[1] : null;
    }

    function setVideoBitrates(sdp: any, params: any) {
        params = params || {};
        const xgoogle_min_bitrate = params.min;
        const xgoogle_max_bitrate = params.max;

        const sdpLines = sdp.split('\r\n');

        // VP8
        const vp8Index = findLine(sdpLines, 'a=rtpmap', 'VP8/90000');
        let vp8Payload;
        if (vp8Index) {
            vp8Payload = getCodecPayloadType(sdpLines[vp8Index]);
            console.log("vp8Payload", vp8Payload)
        }

        if (!vp8Payload) {
            return sdp;
        }

        const rtxIndex = findLine(sdpLines, 'a=rtpmap', 'rtx/90000');
        let rtxPayload;
        if (rtxIndex) {
            rtxPayload = getCodecPayloadType(sdpLines[rtxIndex]);
            console.log("rtxPayload", rtxPayload)
        }

        if (!rtxIndex) {
            return sdp;
        }

        const rtxFmtpLineIndex = findLine(sdpLines, 'a=fmtp:' + rtxPayload.toString(), "");
        if (rtxFmtpLineIndex !== null) {
            let appendrtxNext = '\r\n';
            appendrtxNext += 'a=fmtp:' + vp8Payload + ' x-google-min-bitrate=' + (xgoogle_min_bitrate || '228') + '; x-google-max-bitrate=' + (xgoogle_max_bitrate || '228');
            sdpLines[rtxFmtpLineIndex] = sdpLines[rtxFmtpLineIndex].concat(appendrtxNext);
            console.log("sdp line", sdpLines[rtxFmtpLineIndex]);
            sdp = sdpLines.join('\r\n');
        }

        return sdp;
    }

    function setOpusAttributes(sdp: any, params: any) {
        params = params || {};

        const sdpLines = sdp.split('\r\n');

        // Opus
        const opusIndex = findLine(sdpLines, 'a=rtpmap', 'opus/48000');
        let opusPayload;
        if (opusIndex) {
            opusPayload = getCodecPayloadType(sdpLines[opusIndex]);
        }

        if (!opusPayload) {
            return sdp;
        }

        const opusFmtpLineIndex = findLine(sdpLines, 'a=fmtp:' + opusPayload.toString(), "");
        if (opusFmtpLineIndex === null) {
            console.log("no opus fmt line");
            return sdp;
        }

        let appendOpusNext = '';
        appendOpusNext += '; stereo=' + (typeof params.stereo != 'undefined' ? params.stereo : '1');
        appendOpusNext += '; sprop-stereo=' + (typeof params['sprop-stereo'] != 'undefined' ? params['sprop-stereo'] : '1');

        if (typeof params.maxaveragebitrate != 'undefined') {
            appendOpusNext += '; maxaveragebitrate=' + (params.maxaveragebitrate || 128 * 1024 * 8);
        }

        if (typeof params.maxplaybackrate != 'undefined') {
            appendOpusNext += '; maxplaybackrate=' + (params.maxplaybackrate || 128 * 1024 * 8);
        }

        if (typeof params.cbr != 'undefined') {
            appendOpusNext += '; cbr=' + (typeof params.cbr != 'undefined' ? params.cbr : '1');
        }

        if (typeof params.useinbandfec != 'undefined') {
            appendOpusNext += '; useinbandfec=' + params.useinbandfec;
        }

        if (typeof params.usedtx != 'undefined') {
            appendOpusNext += '; usedtx=' + params.usedtx;
        }

        if (typeof params.maxptime != 'undefined') {
            appendOpusNext += '\r\na=maxptime:' + params.maxptime;
        }

        sdpLines[opusFmtpLineIndex] = sdpLines[opusFmtpLineIndex].concat(appendOpusNext);

        sdp = sdpLines.join('\r\n');
        return sdp;
    }

    return {
        setApplicationSpecificBandwidth: function (sdp: any, bandwidth: any, isScreen: any) {
            return setBAS(sdp, bandwidth, isScreen);
        },
        setVideoBitrates: function (sdp: any, params: any) {
            return setVideoBitrates(sdp, params);
        },
        setOpusAttributes: function (sdp: any, params: any) {
            return setOpusAttributes(sdp, params);
        }
    };
})();


class WebrtcConnector extends Component<IWebrtcConnectorProperties, any> {
    private audio: any;
    private video: any;
    private video_echo: any;
    private pc?: RTCPeerConnection;
    private dc?: RTCDataChannel;
    private constraints: MediaStreamConstraints;
    private listener: any;
    private connectionInterval: any;

    public constructor(props: any) {
        super(props);

        this.state = {
            useStun: false,
        };

        this.audio = createRef();
        this.video = createRef();
        this.video_echo = createRef();

        this.constraints = {
            audio: isIOS || isMobileSafari ? true : {
                echoCancellation: false,
                // @ts-ignore
                autoGainControl: false,
                noiseSuppression: false,
                highpassFilter: {ideal: false},
                typingNoiseDetection: {ideal: false},
                audioMirroring: {ideal: false},
                sampleRate: 48000,
                sampleSize: 16,
                volume: 1.0
            },
            video: {facingMode: {ideal: "user"}, height: 640, width: 640}
        };
    }

    componentDidMount(): void {

        if (!this.props.backendConnected) {
            this.start();
        }

        this.connectionInterval = setInterval(this.reconnect.bind(this), 15000);

        const iOS = isIOS || isMobileSafari;
        const eventName = iOS ? 'pagehide' : 'beforeunload';
        const listener = (event: any) => {
            console.log("stop streaming on page unloading/hiding");
            try {
                this.stop();
            } catch (e) {
                console.error(e);
            }
        };
        this.listener = listener;
        window.addEventListener(eventName, listener);

        window.addEventListener("pageshow", (event: PageTransitionEvent) => {
            if (!this.props.backendConnected) {
                this.start();
            }
        });
    }

    componentWillUnmount(): void {
        console.log('componentWillUnmount()');
        this.stop();

        const iOS = isIOS || isMobileSafari;
        const eventName = iOS ? 'pagehide' : 'beforeunload';
        window.removeEventListener(eventName, this.listener);
        clearInterval(this.connectionInterval)
    }

    private reconnect() {
        if (!this.props.backendConnected) {
            console.log('Trying to reconnect.');
            this.stop();
            this.start();
        } else {
            console.log('Reconnect not needed.');
        }
    }

    private start() {
        console.log('Establishing peer connection');
        let pc = this.createPeerConnection();
        this.pc = pc

        let dataChannelParams: RTCDataChannelInit = {ordered: true, negotiated: true, id: 0};

        this.dc = pc.createDataChannel('chat', dataChannelParams);

        if (this.dc) {
            this.dc.onclose = () => {
                console.log('Data channel closed.');
                this.props.onDisconnect();
            };

            this.dc.onopen = (ev: any) => {
                console.log('Data channel opened', ev);

                console.log('Successfully connected');
                this.props.onConnect(true);
                let message = JSON.stringify({
                    type: "register",
                    data: {
                        user_id: this.props.userId,
                        timestamp: new Date().getTime() / 1000.0
                    }
                });
                try {
                    this.dc && this.dc.send(message);
                    console.log('sent datachannel message', message);
                } catch (e) {
                    console.error(e);
                }
            };
            this.dc.onerror = (er: any) => {
                console.log('Data channel error', er);
            }
            this.dc.onmessage = (evt: any) => {
                try {
                    const json = JSON.parse(evt.data);
                    if (json.type === 'status') {
                        this.props.onAudioMatched(json.data.audio_matched);
                        this.props.onFaceRecognized(json.data.face_recognized);
                        json.data.video_bitrate && this.props.onBitrateReceived(json.data.video_bitrate);
                        this.props.onSpeechRecognitionResultReceived(json.data.speech_recognition_result);
                        this.props.onDialogResponseReceived(json.data.dialog_response);
                    } else if (json.type === 'stream_name') {
                        console.log('Stream name msg i guess')
                    } else if (json.type === 'gesture_matches') {
                        const matches: IGestureMatch[] = json.data.gesture_matches;
                        const sortedMatches = matches.sort((a: IGestureMatch, b: IGestureMatch) =>
                            !a.context_duration || !b.context_duration ? 0 :
                                a.context_duration < b.context_duration
                                    ? -1
                                    : a.context_duration > b.context_duration
                                        ? 1
                                        : 0);
                        this.props.onMatch(sortedMatches);
                    } else {
                        console.log('Got unknown message type.')
                    }
                } catch (e) {
                    console.log(`Error on receiving data channel message: ${evt.data}`, e);
                }
            };
        }

        navigator.mediaDevices.getUserMedia(this.constraints).then((stream) => {

            stream.getTracks().forEach((track) => {
                this.pc && this.pc.addTrack(track, stream) && console.log("added track", track);
            });

            this.video.current.setAttribute('autoplay', '');
            this.video.current.setAttribute('playsinline', '');
            this.video.current.muted = true;
            this.video.current.volume = 0;
            this.video.current.srcObject = stream;
            this.video.current.onloadedmetadata = (e: any) => {
                this.video && this.video.current && this.video.current.play();
                if (isMobileSafari || isSafari) {
                    this.video.current.onclick = (e: any) => this.video.current.play();
                }
            };

            return this.negotiate();
        }, function (err) {
            console.error('Could not acquire media: ' + err);
        });

    }

    private stop() {
        console.info('stop()');

        this.dc && this.dc.close();

        this.pc && this.pc.getTransceivers && this.pc.getTransceivers().forEach((transceiver: any) => {
            if (transceiver.stop) {
                transceiver.stop()
            }
        })

        this.pc && this.pc.getSenders().forEach((sender: RTCRtpSender) => {
            sender.track && sender.track.stop();
        });

        // setTimeout(() => {
        this.pc && this.pc.close()
        // }, 500);
    }

    private negotiate() {
        //this.pc && this.pc.addTransceiver('video', {direction: 'sendonly'})
        this.pc && this.pc.addTransceiver('audio', {direction: 'sendrecv', sendEncodings: [{maxBitrate:510000}]})
        if (!this.pc) throw new Error("pc undefined");
        return this.pc.createOffer().then((offer: RTCSessionDescriptionInit) => {
            if (!this.pc) throw new Error("pc undefined");
            let bandwidth = {
                screen: 300,
                audio: 100,
                video: 1024
            };
            let isScreenSharing = false;

            let sdp = BandwidthHandler.setApplicationSpecificBandwidth(offer.sdp, bandwidth, isScreenSharing);

            sdp = BandwidthHandler.setOpusAttributes(sdp, {maxaveragebitrate: 510000});
            // sdp = BandwidthHandler.setVideoBitrates(sdp, {min: 500, max: 3000});

            sdp = sdp.replace('minptime=10;useinbandfec=1', 'minptime=40;ptime=40;useinbandfec=1;stereo=1;sprop-stereo=1;maxaveragebitrate=510000\\r');

            offer.sdp = sdp

            return this.pc.setLocalDescription(offer);
        }).then(() => {
            if (!this.pc) throw new Error("pc undefined");
            // wait for ICE gathering to complete
            return new Promise((resolve) => {
                if (!this.pc) throw new Error("pc undefined");
                if (this.pc.iceGatheringState === 'complete') {
                    resolve();
                } else {
                    let checkState = () => {
                        if (!this.pc) throw new Error("pc undefined");
                        if (this.pc.iceGatheringState === 'complete') {
                            this.pc.removeEventListener('icegatheringstatechange', checkState);
                            resolve();
                        }
                    };

                    this.pc.addEventListener('icegatheringstatechange', checkState.bind(this));
                }
            });
        }).then(() => {
            if (!this.pc) throw new Error("pc undefined");

            let offer = this.pc.localDescription;

            if (!offer) throw new Error("offer undefined");

            let b = JSON.stringify({
                sdp: offer.sdp,
                type: offer.type,
            });

            b = b.replace('minptime=10;useinbandfec=1', 'minptime=40;ptime=40;useinbandfec=1;stereo=1;sprop-stereo=1;maxaveragebitrate=510000\\r');

            let domain = getBackendDomain();

            return fetch('https://' + domain + '/offer', {
                body: b,
                headers: {
                    'Content-Type': 'application/json',
                },
                method: 'POST'
            });
        }).then((response: any) => {
            return response.json();
        }).then((answer: any) => {
            if (!this.pc) throw new Error("pc undefined");
            return this.pc.setRemoteDescription(answer);
        }).catch((e: any) => {
            console.log(e);
        });
    }

    private createPeerConnection() {
        let config = {
            sdpSemantics: 'unified-plan'
        };

        if (this.state.useStun) {
            // @ts-ignore
            config.iceServers = [{
                urls: ['stun:stun.l.google.com:19302']
            }];
        }


        // @ts-ignore
        let pc = new RTCPeerConnection(config);

        // register some listeners to help debugging
        pc.addEventListener('icegatheringstatechange', function () {
            console.log('icegatheringstatechange', pc.iceGatheringState);
        }, false);

        pc.addEventListener('iceconnectionstatechange', () => {
            console.log('iceconnectionstatechange', pc.iceConnectionState);
            if (pc.iceConnectionState === 'disconnected') {
                this.props.onDisconnect();
            }
        }, false);

        pc.addEventListener('signalingstatechange', () => {
            console.log('signalingstatechange', pc.signalingState);
            if (pc.signalingState === 'closed' || pc.signalingState === 'disconnected') {
                this.props.onDisconnect();
            }
        }, false);

        // connect audio / video
        pc.addEventListener('track', (evt: RTCTrackEvent) => {
            if (evt.track.kind === 'video') {
                console.log('wiring video echo out');
                this.video_echo.current.setAttribute('autoplay', '');
                this.video_echo.current.setAttribute('muted', '');
                this.video_echo.current.setAttribute('playsinline', '');
                this.video_echo.current.srcObject = evt.streams[0];
                this.video_echo.current.onloadedmetadata = (e: any) => {
                    this.video_echo && this.video_echo.current && this.video_echo.current.play();
                };
            } else {
                console.log('wiring audio out');
                this.audio.current.srcObject = evt.streams[0];
                const gain = isIOS && osVersion === "14" ? 10 : 1;
                this.connectToSpeaker(evt.streams[0], gain);
            }
        });

        return pc;
    }

    public reportTrainingPlayback(youtubeId: string,
                                  timestamp: number,
                                  offset: number,
                                  duration: number,
                                  currentTimestamp: number) {
        // return; // do not yet report somewhat erroneous values. TODO: fix
        const msg = {
            type: 'playback',
            data: {
                youtubeId,
                timestamp,
                offset,
                length: duration,
                currentTimestamp
            }
        };
        this._sendMessage(msg);
    }

    public reportModeChange(mode: string) {
        const msg = {
            type: 'mode',
            data: {
                mode
            }
        };
        this._sendMessage(msg);
    }

    public reportControlState(enabled: boolean) {
        const msg = {
            type: 'controlMode',
            data: {
                enabled
            }
        }
        this._sendMessage(msg);
    }

    public sendMusicIntent(intent: MusicIntent) {
        const msg = {
            type: 'musicIntent',
            data: intent
        };
        this._sendMessage(msg);
    }

    public sendFreeText(text: string) {
        const msg = {
            type: 'freeText',
            data: {
                value: text
            }
        };
        this._sendMessage(msg);
    }

    public sendPlaybackControl(action: string, target?: string, value?: number) {
        const msg = {
            type: 'musicIntent',
            data: {
                action: action,
                target: target,
                value: value
            }
        };
        this._sendMessage(msg);
    }

    public sendPerformanceDirection(direction: string) {
        const msg = {
            type: 'musicIntent',
            data: {
                action: 'performance_direction',
                performanceDirection: direction
            }
        };
        this._sendMessage(msg);
    }

    private _sendMessage(msg: {type: string, data: object}) {
        if (this.dc && this.dc.readyState === 'open') {
            console.log(msg.type, msg);
            this.dc.send(JSON.stringify(msg))
        } else {
            console.log('Failed to send message of type ' + msg.type);
        }
    }

    public connectToSpeaker = function (remoteAudioStream: MediaStream, gain: number) {
        const context: AudioContext = new AudioContext();
        const audioNode = context.createMediaStreamSource(remoteAudioStream);
        const gainNode: GainNode = context.createGain();
        // some device volume too low ex) iPad
        gainNode.gain.value = gain;
        audioNode.connect(gainNode);
        gainNode.connect(context.destination);
    }

    public render() {

        let video = <video ref={this.video} style={{
            height: '46vh',
            transform: 'rotateY(180deg)',
            WebkitTransform: 'rotateY(180deg)',
            MozTransform: 'rotateY(180deg)',
            display: this.props.backendConnected ? 'block' : 'none'
        }}/>;

        let video_echo = <video ref={this.video_echo} style={{
            height: '46vh',
            transform: 'rotateY(180deg)',
            WebkitTransform: 'rotateY(180deg)',
            MozTransform: 'rotateY(180deg)',
            display: 'none'
        }}/>;


        let audio = <audio ref={this.audio}/>;

        let placeholder = (
            <div className="Placeholder"
                 style={{display: this.props.backendConnected ? 'none' : 'block'}}>
                <div>
                    <FormattedMessage
                        id="app.webrtc.noConnection"
                        defaultMessage="No connection."
                    />
                </div>
                <div>
                    <FormattedMessage
                        id="app.webrtc.reconnectAttempt"
                        defaultMessage="Attempting to connect..."
                    />
                </div>
            </div>
        );

        return (
            <div className="Main-webrtc">
                {video}
                {video_echo}
                {audio}
            </div>
        );
    }

}


export default WebrtcConnector;
