/*
 Mainly dimensional constants for the cases when we can't use css variables defined in index.css
 */

// Dimensions of the fixed-sized parts of the layout: header and thumbnail/player container
export const HEADER_HEIGHT = 60;
export const HEADER_MOBILE_HEIGHT = 60;
export const THUMBNAIL_PLAYER_HEIGHT = 150;
export const SCORE_VIEWER_OPTIMAL_WIDTH = 430;
export const SCORE_VIEWER_OPTIMAL_HEIGHT = 600;
export const SCORE_VIEWER_MARGIN = 16;
export const SCORE_VIEWER_PLACEHOLDER_HEIGHT = 305;

// Dimensions of the thumbnails in carousel below
export const THUMBNAIL_HEIGHT = 68;
export const THUMBNAIL_WIDTH = 90;

// Dimensions of selected video thumbnail and video player
export const SELECTED_THUMBNAIL_HEIGHT = 100;
export const SELECTED_THUMBNAIL_WIDTH = 150;

// Heuristic screen sizes, upon which the number of the displayed thumbnails is decided
export const WIDE_SCREEN_WIDTH = 1370;
export const MEDIUM_SCREEN_WIDTH = 1140;
export const NARROW_SCREEN_WIDTH = 955;

// the number of maximal cached players for video playback
export const MAX_CASHED_PLAYERS = 15;
